@import url("https://fonts.googleapis.com/css?family=Montserrat:400,800");

* {
    box-sizing: border-box;
}

html body {
    font-size: 14px;
}

.login_page {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: "Montserrat", sans-serif;
    height: 100vh;
}

body {
    font-size: 14px;
}

.table.table-bordered td {
    font-size: 14px;
}

.custom_login h1 {
    font-weight: bold;
    margin: 10px 0;
    font-size: 2rem;
}

.navbar.navbar-expand-xxl.navbar-light {
    align-items: baseline;
    padding-left: 23px;
    flex-wrap: wrap;
    width: 70px;
    margin: 0px 0 0;
    /* padding-top: 65px; */
    transition: all 0.5s ease;
}

button.sidelogobar {
    margin: 0 0 15px;
}

.navbar.navbar-expand-xxl.navbar-light {
    display: block !important;
}

.navbar-toggler {
    margin-left: -11px !important;
    transition: all 0.5s ease;
}


/* .showicon .navbar-toggler {
  margin-left: 0 !important; transition: all 0.5s ease;
} */

.nav-item:first-child {
    margin: 0 0 20px;
}

.navbar.navbar-expand-xxl.navbar-light.col-2.showicon {
    width: 200px;
}

.bg-right.col-10 {
    width: calc(100% - 200px);
    transition: 0.5s ease;
}

.bg-right.col-11 {
    width: calc(100% - 70px);
    transition: 0.5s ease;
    position: relative;
    z-index: 999;
}

button.btn:hover {
    color: var(--bs-btn-hover-color);
    background: none !important;
    border: none !important;
}

.navbar.navbar-expand-xxl .navbar-nav {
    flex-direction: column;
}

.nav-item.i-link {
    margin: -5px 0 15pxpx;
}

.navbar .navbar-toggler {
    margin: 60px 0 0px;
    width: 100%;
    left: 0;
    display: flex;
    justify-content: space-between;
    padding: 0 20px 0px 0;
    align-items: center;
}

.showicon .sidebar-logo {
    max-width: 55px;
    width: auto;
}

#logo-name__icon {
    height: 50px;
    line-height: 50px;
    width: 30px;
    transform: rotate(180deg);
    position: absolute;
    top: 4px;
    display: none;
}

.showicon #logo-name__icon {
    transform: rotate(0deg);
    right: -31px;
    top: 4px;
}

.sidebar-logo {
    max-width: 55px;
}

.bx-arrow-from-right:before {
    color: #333;
}

.custom_login p {
    font-size: 14px;
    font-weight: 100;
    line-height: 20px;
    letter-spacing: 0.5px;
    margin: 20px 0 30px;
}

.custom_login span {
    font-size: 12px;
}

.custom_login a.forgot_password {
    color: #000;
    font-size: 12px;
    text-decoration: none;
    margin: 15px 0;
    display: inline-block;
    padding-left: 10px;
}

.signup-btn-l {
    font-size: 16px !important;
    font-weight: 600;
}

.custom_login a.forgot_password:hover {
    color: #333;
}

.custom_login button {
    border-radius: 20px;
    border: 1px solid #43a8a0;
    background-color: #43a8a0;
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    padding: 12px 45px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: transform 80ms ease-in;
    cursor: pointer;
}

.custom_login button:hover {
    color: #000;
}

.custom_login .left-container {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 50px;
    height: 100%;
    text-align: center;
}

.pre-wrap {
    font-family: "Montserrat", sans-serif;
    margin-top: 10px;
}

.custom_login input {
    background-color: #eee;
    border: none;
    padding: 12px 15px;
    margin: 10px 0 0;
    width: 100%;
    font-family: sans-serif;
    font-size: 14px;
    line-height: normal;
}

/* .container.custom_login {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    position: relative;
    overflow: hidden;
    width: 820px;
    max-width: 100%;
    min-height: 600px;
} */

.container.custom_login {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    position: relative;
    overflow: hidden;
    width: 820px;
    max-width: 100%;
    min-height: 575px;
}

.custom_login .form-container {
    position: absolute;
    top: 0;
    height: 100%;
}

.custom_login .log-in-container {
    left: 0;
    width: 50%;
    z-index: 2;
}

.custom_login .overlay-container {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
}

.custom_login .overlay {
    background: #f9f9f9;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
    color: #ffffff;
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
}

.custom_login .overlay-panel {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    text-align: center;
    top: 0;
    height: 100%;
    width: 50%;
}

.custom_login .overlay-right {
    right: 0;
}

.menu-svg {
    max-width: 21px;
}

.navbar-light .navbar-toggler {
    color: #fff;
    border: 0px;
    padding: 0px;
    position: absolute;
    top: -48px;
    left: 20px;
    max-width: 135px;
}

.navbar-light .navbar-toggler-icon {
    background: url(assets/img/hamburger.svg);
    background-size: contain;
    background-repeat: no-repeat;
    height: 22px;
}

.navbar-toggler:focus {
    box-shadow: none;
}

.navbar {
    background: #d9d9d9;
    align-items: flex-start;
    padding: 20px;
}

.custom-header {
    background: #43a8a0;
}

.custom-header .input-group .form-control,
.custom-header .input-group {
    background: #6fa8dc;
    border-radius: 5px !important;
    color: #fff;
    border-color: #6fa8dc;
}

.custom-header h2 {
    font-size: 24px;
    color: #fff;
    font-weight: 400;
    margin: 0 0 3px;
}

.custom-header input::placeholder {
    color: #fff !important;
}

.custom-header input:-ms-input-placeholder {
    color: #fff !important;
}

.custom-header input::-ms-input-placeholder {
    color: #fff !important;
}

.input-group .btn {
    border: 0px;
}

.top-right-sec .input-group .btn {
    height: 34px;
    padding: 0 10px;
}

.top-right-sec .input-group .btn img {
    margin: -3px 0 0;
}

.input-group-append {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 99;
}

.profile-name {
    color: #fff;
    background: #f1c232;
    text-decoration: none;
    height: 35px;
    width: 35px;
    display: inline-block;
    text-align: center;
    line-height: 35px;
    font-size: 15px;
    border-radius: 50%;
    border: 0;
}

.profile-name:hover {
    color: #000;
}

.profile-name:after {
    display: none;
}

.right-head {
    text-align: right;
}

.right-head>a,
.right-head>div {
    margin: 0 0 0 20px;
}

.custom-header .row {
    margin-left: 40px;
    align-items: center;
}

.nav-item span {
    font-weight: normal;
    color: #585858;
    display: inline-block;
    vertical-align: middle;
    transition: opacity 0.4s ease;
    opacity: 0;
    height: 21px;
}

.nav-item a {
    display: flex;
    text-decoration: none;
}

.nav-item {
    margin: 0 0 15px;
}

.showicon .nav-item span {
    font-size: 14px;
    opacity: 1;
    transition: opacity 0.6s ease;
}

.nav-item span img {
    margin: -1px 15px 0 0;
    display: inline-block;
    vertical-align: middle;
}

.showicon .nav-item span img {
    margin: -4px 15px 0 0;
}

.blue-btn,
.filter,
button.btn.btn-outline-dark {
    background: #43a8a0;
    color: #fff;
    padding: 4px 20px 4px 15px;
    text-decoration: none;
    display: inline-block;
    vertical-align: middle;
    border: 1px solid #43a8a0;
    border-radius: 5px;
}

.blue-btn:hover,
button.btn.btn-outline-dark:hover {
    color: #43a8a0;
    background: #fff;
    border: 1px solid #43a8a0;
}

.filter,
button.btn.btn-outline-dark+button.btn.btn-outline-dark {
    padding: 4px 25px;
    background: #fff;
    color: #43a8a0;
    border: 1px solid #dee2e6;
}

.filter:hover,
button.btn.btn-outline-dark+button.btn.btn-outline-dark:hover {
    color: #fff;
    background: #43a8a0;
}

.filter {
    padding: 4px 25px;
    background: #fff;
    color: #43a8a0;
    border: 1px solid #dee2e6;
}

.ng-select .ng-has-value .ng-placeholder {
    display: none;
}

.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"],
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"] {
    --bs-form-select-bg-icon: url(data:image/svg + xml,
 %3csvgxmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" width="12" height="12" fill="none" stroke="%23dc3545" %3e%3ccirclecx="6" cy="6" r="4.5" /%3e%3cpathstroke-linejoin="round" d="M5.8 3.6h.4L6 6.5z" /%3e%3ccirclecx="6" cy="8.2" r=".6" fill="%23dc3545" stroke="none" /%3e%3c/svg%3e);
    padding-right: 4.125rem;
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
    border: 1px solid red;
}

.actionicons {
    display: block;
    width: 48px;
    text-align: right;
}

.actionicons a {
    display: none;
}

body .table-bordered tr:hover .actionicons a {
    display: inline-block;
    font-size: 19px;
    margin-left: 5px;
    background: #dfdfdf59;
}

body .table-bordered tr:hover td {
    background: #dfdfdf59;
}

.pagination-main .page-link {
    background: #43a8a0;
    color: #fff;
    cursor: pointer;
}

.pagination-main .page-link:hover {
    color: #43a8a0;
    background: #fff;
}

.bg-right {
    background: #f3f3f3;
    padding: 0px;
}

.custom-section {
    margin: 0;
}

.top-row {
    border-bottom: 1px solid #b7b7b7;
    margin: 0 0 20px;
    padding: 20px;
}

.bottom-table {
    margin: 20px;
}

.bottom-table table th {
    background: #cfe2f3;
    position: relative;
    vertical-align: middle;
}

.circle-num {
    background: #63c9c1;
    width: 60px;
    height: 30px;
    display: inline-block;
    text-align: center;
    border-radius: 50px;
    line-height: 30px;
    margin: 0 auto;
}

.bottom-table table td:nth-child(4) {
    text-align: center;
}

.form-check-label img {
    max-width: 35px;
}

.bottom-table .table {
    margin: 0;
}

.dots-btn {
    background: #fff;
    border: 1px solid #dee2e6;
    height: 34px;
    display: inline-block;
    width: 50px;
    text-align: center;
    border-radius: 5px;
    line-height: 28px;
}

.top-right-sec .input-group .form-control {
    border: 1px solid #dee2e6;
    border-radius: 5px !important;
    padding: 0 15px;
}

.table .form-check-input[type="checkbox"],
.table .form-check-label {
    display: inline-block;
    vertical-align: middle;
    float: none;
}

.table .form-check-input[type="checkbox"] {
    margin: 0 10px 0 0;
}

.table .form-check {
    padding-left: 10px;
}

.pagination-main {
    padding: 20px;
    /* border-top: 1px solid #b7b7b7; */
}

.page-item.active .page-link {
    background-color: #63c9c1;
    border-color: #63c9c1;
    font-weight: bold;
}

.notification {
    position: relative;
}

.notification #staticBackdrop {
    position: absolute;
    width: 360px;
    right: 0;
    left: auto;
    height: auto;
    top: 10px;
}

.notofication_row img {
    border-radius: 50%;
    margin: 0 10px 0;
    display: inline-block;
    vertical-align: top;
}

.notofication_row span {
    line-height: normal;
    display: inline-block;
    vertical-align: top;
    text-align: left;
}

.menu-svg {
    max-width: 21px;
}

.navbar-light .navbar-toggler {
    color: #fff;
    border: 0px;
    padding: 0px;
    position: absolute;
    top: -48px;
    left: 20px;
}

.navbar-light .navbar-toggler-icon {
    background: url(assets/img/hamburger.svg);
    background-size: contain;
    background-repeat: no-repeat;
    height: 22px;
}

.navbar-toggler:focus {
    box-shadow: none;
}

.navbar {
    background: #d9d9d9;
    align-items: flex-start;
    padding: 20px;
}

.custom-header {
    background: #43a8a0;
}

.custom-header .input-group .form-control,
.custom-header .input-group {
    background: #81e1d9;
    border-radius: 5px !important;
    color: #fff;
    border-color: #81e1d9;
}

.custom-header h2 {
    font-size: 24px;
    color: #fff;
    font-weight: 400;
    margin: 0 0 3px;
}

.custom-header input::placeholder {
    color: #fff !important;
}

.custom-header input:-ms-input-placeholder {
    color: #fff !important;
}

.custom-header input::-ms-input-placeholder {
    color: #fff !important;
}

.input-group .btn {
    border: 0px;
}

.top-right-sec .input-group .btn {
    height: 34px;
    padding: 0 10px;
}

.top-right-sec .input-group .btn img {
    margin: -3px 0 0;
}

.input-group-append {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 99;
}

.profile-name {
    color: #fff;
    background: #f1c232;
    text-decoration: none;
    height: 35px;
    width: 35px;
    display: inline-block;
    text-align: center;
    line-height: 35px;
    font-size: 15px;
    border-radius: 50%;
    border: 0;
}

.profile-name:hover {
    color: #000;
}

.profile-name:after {
    display: none;
}

.right-head {
    text-align: right;
}

.right-head>a,
.right-head>div {
    margin: 0 0 0 20px;
}

.custom-header .row {
    margin-left: 0px;
    align-items: center;
    --bs-gutter-x: 0rem !important;
    margin: 0 !important;
    padding: 0 !important;
}

.nav-item span {
    font-weight: normal;
    color: #585858;
    display: inline-block;
    vertical-align: middle;
}

.nav-item span img {
    margin: -1px 15px 0 0;
    display: inline-block;
    vertical-align: middle;
}

.blue-btn,
.filter {
    background: #43a8a0;
    color: #fff;
    padding: 4px 20px 4px 15px;
    text-decoration: none;
    display: inline-block;
    vertical-align: middle;
    border: 1px solid #43a8a0;
    border-radius: 5px;
}

.table-bordered tr:hover td {
    background: #81e1d9;
    cursor: pointer;
}

.blue-btn:hover {
    color: #43a8a0;
    background: #fff;
    border: 1px solid #43a8a0;
}

.row.custom-section {
    height: 100vh;
}

.close {
    background: none;
    border: 0px;
}

.filter:hover {
    color: #fff;
    background: #43a8a0;
}

.filter {
    padding: 4px 25px;
    background: #fff;
    color: #43a8a0;
    border: 1px solid #43a8a0;
}

.bg-right {
    background: #f3f3f3;
    padding: 0px;
}

.custom-section {
    margin: 0;
}

#panelsStayOpen-heading-three-one button {
    /* background: #63c9c1; */
    background: #63c9c1;
    font-family: arial;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
}

.tag-icon {
    font-size: 20px;
    color: #2f4f4f;
    margin: 0px 11px 0 0;
    position: relative;
    top: 2px;
    height: 20px;
    line-height: 20px;
}

.top-row {
    border-bottom: 1px solid #b7b7b7;
    margin: 0 0 20px;
    padding: 20px;
}

.bottom-table {
    margin: 0px;
}

.filter-open {
    padding: 0 20px;
}

.bottom-table table th {
    background: #cfe2f3;
}

.modal-content .modal-header button {
    min-width: inherit;
    font-size: 24px;
}

.modal-header {
    justify-content: space-between;
}

.ng-dropdown-panel {
    box-sizing: border-box;
    position: absolute;
    opacity: 0;
    width: 100%;
    z-index: 1050;
    -webkit-overflow-scrolling: touch;
    background: #fff;
    padding: 10px;
    left: 0px;
    border: 1px solid #dfdfdf;
    margin-top: 6px;
}

.circle-num {
    background: #63c9c1;
    color: #fff;
    width: 60px;
    height: 30px;
    display: inline-block;
    text-align: center;
    border-radius: 50px;
    line-height: 30px;
    margin: 0 auto;
}

.modal-content button {
    min-width: 86px;
}

.bi-pencil-square::before {
    color: #43a8a0;
}

.bi-trash::before {
    color: #ff0000;
}

.bottom-table table td:nth-child(4) {
    text-align: center;
}

.form-check-label img {
    max-width: 35px;
}

.bottom-table .table {
    margin: 0;
}

.dots-btn {
    background: #fff;
    border: 1px solid #dee2e6;
    height: 34px;
    display: inline-block;
    width: 50px;
    text-align: center;
    border-radius: 5px;
    line-height: 28px;
}

.top-right-sec .input-group .form-control {
    border: 1px solid #dee2e6;
    border-radius: 5px !important;
    padding: 0 15px;
}

.table .form-check-input[type="checkbox"],
.table .form-check-label {
    display: inline-block;
    vertical-align: middle;
    float: none;
}

.table .form-check-input[type="checkbox"] {
    margin: 0 10px 0 0;
}

.table .form-check {
    padding-left: 10px;
}

.pagination-main {
    padding: 20px;
    /* border-top: 1px solid #b7b7b7; */
}

.page-item.active .page-link {
    background-color: #63c9c1;
    border-color: #63c9c1;
    font-weight: bold;
}

.notification {
    position: relative;
}

.notification #staticBackdrop {
    position: absolute;
    width: 360px;
    right: 0;
    left: auto;
    height: auto;
    top: 10px;
}

.notofication_row img {
    border-radius: 50%;
    margin: 0 10px 0;
    display: inline-block;
    vertical-align: top;
}

.notofication_row span {
    line-height: normal;
    display: inline-block;
    vertical-align: top;
    text-align: left;
}

.svg-inline--fa {
    display: var(--fa-display, inline-block);
    height: 0.8em;
    overflow: visible;
    vertical-align: -0.125em;
}

.modal-content label {
    margin: 0 0 7px;
}

@media screen and (max-width: 550px) {
    .custom_login .log-in-container {
        width: 100%;
    }

    .custom_login .overlay {
        width: 100%;
        left: auto;
        height: auto;
    }

    .custom_login .overlay-container {
        position: relative;
        top: 40px;
        left: auto;
        width: 100%;
        height: auto;
    }

    .custom_login .form-container {
        position: relative;
        top: auto;
        height: 100%;
        padding: 30px 0;
        background: #fff;
    }

    .custom_login .overlay-panel {
        position: relative;
        display: block;
        padding: 20px;
        text-align: center;
        top: 0;
        height: auto;
        width: 100%;
    }

    .container.custom_login {
        max-width: 100%;
        background: transparent;
        box-shadow: none;
        min-height: 100vh;
        padding: 0;
    }

    .login_page {
        display: block;
        min-height: 100vh;
        padding: 30px 10px;
    }
}

.table-bordered tr:hover td {
    background: #dfdfdf59 !important;
}

.filter.f-gray {
    padding: 5px 10px;
    background: #d5d5d5;
    border: 0px;
    /* position: absolute; */
    margin-top: 77px;
    color: #333;
}

/* .candidatelist {
    position: absolute;
    background: #81e1d9;
    width: 100%;
    padding: 0px 15px;
    border-radius: 3px;
    margin-top: 0px;
    max-width: 401px;
    z-index: 99;
} */

.candidatelist {
    position: absolute;
    background: #81e1d9;
    width: 100%;
    padding: 0px 15px;
    border-radius: 3px;
    margin-top: 0px;
    max-width: 401px;
    max-height: 300px;
    overflow-y: auto;
    z-index: 99;
}

.custom-dropdown.form-select {
    width: 122px !important;
}


/* Project details - 13-06-2023 */

.paginationBlue .page-item.active .page-link {
    background-color: #63c9c1;
    border-color: #63c9c1;
}

.form-check-input:checked {
    background-color: #43a8a0 !important;
    border-color: #43a8a0 !important;
}

.square-num {
    width: 40px;
    height: 40px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    border: 1px solid #c1c1c1;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.radiusbtn {
    text-decoration: none;
    margin: 0px 0px 0px 20px !important;
    background: #63c9c1;
    border: 1px solid #63c9c1;
    padding: 5px 20px;
    color: #fff;
    font-weight: 500;
    border-radius: 5px;
    display: inline-block;
    vertical-align: middle;
    transition: 0.5s all ease-in-out;
}

.producttable .custom-dots-btn {
    margin: 0;
}

.radiusbtn:hover {
    color: #63c9c1;
    border: 1px solid #63c9c1;
    background-color: transparent;
}

.toast-top-center {
    top: 12px;
    margin: 0 auto;
    left: 50%;
}

.toast-container {
    --bs-toast-zindex: 1090;
    position: absolute;
    top: 10px;
    right: -10px;
    padding: 25px;
    display: inline-block;
    height: 30px;
    z-index: var(--bs-toast-zindex);
    width: max-content;
    max-width: 100%;
    pointer-events: none;
    background: 51A351;
    vertical-align: middle;
    color: white;
}


/* TOASTR BUGFIX */

#toast-container>div {
    opacity: 1;
}

.toast {
    font-size: initial !important;
    border: initial !important;
    backdrop-filter: blur(0) !important;
}

.toast-success {
    background-color: #51a351 !important;
    padding: 10px;
    display: inline-block;
    top: 10px;
    right: -10px;
    margin-right: 30px;
}

.toast-error {
    background-color: #bd362f !important;
}

.toast-info {
    background-color: #2f96b4 !important;
}

.toast-warning {
    background-color: #f89406 !important;
}

.password-container {
    width: 466.4px;
    position: relative;
}

.password-container input[type="password"],
.password-container input[type="text"] {
    width: 100% !important;
    padding: 6px 36px 6px 12px;
    box-sizing: border-box;
}

.fa-eye {
    position: absolute;
    top: 40%;
    right: 4%;
    cursor: pointer;
    color: gray;
}

.fa-eye-slash {
    position: absolute;
    top: 40%;
    right: 4%;
    cursor: pointer;
    color: gray;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option:hover {
    background: #63c9c1;
    color: white;
}

.ng-dropdown-panel {
    padding: 5px 0px;
}

.ng-dropdown-panel {
    padding: 10px 0px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    padding: 0px 10px;
}

.filter {
    cursor: pointer;
}

a.dropdown-item {
    cursor: pointer;
}

.table-bordered tr.active td {
    background: #dfdfdf59 !important;
}

body .navbar-toggler:focus {
    box-shadow: none;
}


/**/

@media screen and (max-width: 1199px) {
    .top-row[ng-reflect-page-title="Candidates"]+.bottom-table {
        overflow: auto;
    }

    .top-row[ng-reflect-page-title="Candidates"]+.bottom-table th:first-child {
        min-width: 180px;
    }

    .top-row[ng-reflect-page-title="Candidates"]+.bottom-table th:nth-child(2) {
        min-width: 130px;
    }

    .bottom-table table th:last-child {
        min-width: 70px;
    }

    #panelsStayOpen-heading-three-one button {
        font-size: 16px;
    }

    body .edit[_ngcontent-ng-c111044940] {
        right: 45px;
        top: 0;
    }

    body .details-bottom-table.producttable {
        padding: 0;
    }

    .details-bottom-table.producttable .col-8 .bottom-table {
        overflow: auto;
    }

    .details-bottom-table.producttable .col-8 {
        max-width: calc(100% - 304px);
    }

    #accordionPanelsStayOpenExample-three {
        min-width: 304px;
    }

    .sub-profile br {
        display: none;
    }

    .inner-edit {
        align-items: center;
        display: flex;
    }

    .table.table-bordered td:first-child {
        text-align: center;
    }

    .bottom-table table th:nth-child(3) {
        min-width: 210px;
    }

    body .top-row[ng-reflect-page-title="Clients"]+.bottom-table .table.table-bordered td:first-child {
        text-align: left;
    }

    body .top-row[ng-reflect-page-title="Users"]+.bottom-table .table.table-bordered td:first-child {
        text-align: left;
        min-width: 200px;
    }

    body .top-row[ng-reflect-page-title="Tags"]+.bottom-table .table.table-bordered td:nth-child(4) {
        min-width: 130px;
    }

    body .top-row[ng-reflect-page-title="Tags"]+.bottom-table .table.table-bordered td:nth-child(1) {
        text-align: left;
        min-width: 200px;
    }

    body .top-row[ng-reflect-page-title="Tags"]+.bottom-table .table.table-bordered th:nth-child(3) {
        min-width: 10px !important;
    }

    .top_profile[_ngcontent-ng-c1895669168] img[_ngcontent-ng-c1895669168] {
        max-height: 95px;
    }

    .accordion-item:last-of-type .accordion-button {
        font-size: 16px !important;
    }

    .inner-edit {
        min-width: 40px;
    }
}

@media screen and (max-width: 990px) {
    #content {
        padding: 0 15px;
    }

    .top-row {
        margin: 10px 0 20px;
        padding: 0 5px 15px;
    }

    body .filter {
        margin: 0 !important;
    }

    #n_bar {
        position: relative;
        z-index: 9;
    }

    .row.custom-section {
        display: block;
        background: #f3f3f3;
    }

    .navbar.navbar-expand-xxl.navbar-light app-app-nav {
        padding: 0 23px;
        background: #d9d9d9;
        position: fixed;
        height: 100%;
        top: 70px;
        left: -100%;
        width: 100%;
        z-index: 999;
        transition: all 0.7s ease;
        -webkit-transition: all 0.7s ease;
        -moz-transition: all 0.7s ease;
    }

    .navbar.navbar-expand-xxl.navbar-light.showicon app-app-nav {
        left: 0;
        transition: all 0.7s ease;
        -webkit-transition: all 0.7s ease;
        -moz-transition: all 0.7s ease;
    }

    .navbar.navbar-expand-xxl.navbar-light {
        align-items: baseline;
        padding: 0;
        flex-wrap: wrap;
        width: auto;
    }

    .navbar.navbar-expand-xxl.navbar-light.col-2.showicon {
        width: 100%;
    }

    .navbar.navbar-expand-xxl .navbar-toggler {
        position: absolute;
        top: -49px;
        left: 20px;
    }

    app-app-nav.navbar.navbar-expand-xxl .navbar-nav {
        display: block;
    }

    .showicon .navbar.navbar-expand-xxl .navbar-toggler {
        left: 20px;
    }

    .showicon app-app-nav.ng-tns-c3769565584-0.ng-star-inserted {
        left: 0;
        position: absolute;
        background: #d9d9d9;
        z-index: 999;
    }

    .custom-header h2 {
        width: calc(100% - 390px);
    }

    .nav-item span img,
    .showicon .nav-item span img {
        margin: -4px 15px 0 0;
    }

    .nav-item span {
        font-size: 14px;
    }

    .bg-right.col-11,
    .bg-right.col-10 {
        width: 100%;
    }

    .custom-header h2 {
        font-size: 22px;
        margin: 0;
    }

    .custom-header .row .col-4:nth-child(2) {
        min-width: 270px;
        max-width: 270px;
    }

    .right-head.col-4 {
        max-width: 120px;
        padding: 0;
    }

    .navbar.navbar-expand-xxl .navbar-nav {
        display: inline-block;
        width: 100%;
    }

    .top-right-sec .input-group .btn img {
        margin: -8px 0 0;
    }

    .candidate_details .top-row {
        display: block !important;
    }

    .candidate_details .top-right-details {
        width: 100% !important;
        text-align: left !important;
        margin: 20px 0 0;
    }

    .candidate_details .top-right-details div:last-child {
        float: right;
    }

    .row.details-bottom-table .accordion {
        padding: 0;
        width: 100%;
    }

    .password-container {
        max-width: 100%;
    }

    .fa.fa-eye-slash {
        top: 40px !important;
    }

    .modal-dialog.modal-md .modal-footer {
        justify-content: center;
        gap: 8px;
        flex-direction: row-reverse;
    }

    .row.details-bottom-table.producttable .col-8 {
        max-width: 100%;
        width: 100%;
    }

    .row.details-bottom-table.producttable #accordionPanelsStayOpenExample-three {
        padding: 0 10px;
        margin: 30px 0 0;
    }
}

@media screen and (max-width: 767px) {
    .custom-header .row {
        margin: 0;
    }

    .top_right_profile {
        width: 100%;
        margin: 0 0 15px;
    }

    p.ng-star-inserted {
        display: inline-block;
        margin: 0 0 20px 10px;
    }

    .details-bottom-table.producttable .col-8 {
        max-width: 100%;
        width: 100%;
    }

    body .accordion-header[_ngcontent-ng-c111044940] .edit[_ngcontent-ng-c111044940] {
        margin: 5px 10px 0 0;
    }

    .navbar.navbar-expand-xxl.navbar-light app-app-nav {
        top: 121px;
    }

    #accordionPanelsStayOpenExample-three {
        width: 100%;
        margin: 30px 0 0;
    }

    .custom-header {
        position: relative;
    }

    .custom-header .row {
        margin-left: 0;
    }

    .right-head>a,
    .right-head>div {
        margin: 0;
    }

    .right-head>div:last-child {
        margin: 0 0 0 15px;
    }

    .right-head.col-4 {
        max-width: 75px;
    }

    .custom-header h2 {
        width: calc(100% - 75px);
        text-align: center;
        padding: 0 0 0 25px;
    }

    .custom-header .row .col-4:nth-child(2) {
        min-width: 100%;
        position: absolute;
        top: 70px;
        right: 0;
        padding: 0 20px;
    }

    .custom-header {
        padding-bottom: 70px !important;
    }

    .navbar.navbar-expand-xxl .navbar-toggler {
        top: -98px;
    }

    body .custom-header {
        padding-bottom: 70px !important;
    }

    .bottom-table {
        overflow-x: auto;
    }

    .bottom-table table th:nth-child(4) {
        min-width: 90px;
    }

    .top-left-sec.w-50 {
        width: 100% !important;
        margin: 0 0 20px;
        display: flex;
        justify-content: space-between;
    }

    body .top-right-sec {
        width: 100% !important;
    }

    body .top-row {
        display: inline-block !important;
        width: 100%;
        padding: 10px 5px 20px;
    }

    .filter.ng-star-inserted {
        margin: 0 !important;
    }

    body .pagination {
        width: 100%;
        text-align: center;
        justify-content: center !important;
        margin: 20px 0 0 !important;
        float: left;
    }

    .page-item {
        float: left;
        margin: 0 0 10px;
    }

    body .pagination {
        float: none;
        display: inline-block;
        width: auto;
    }

    .top-right-sec .form-control {
        height: 34px;
    }

    .candidate_details .edit {
        margin: 6px 0 0 !important;
    }

    .custom-dropdown.form-select {
        background-position: calc(100% + 3px) 50%;
    }
}

@media screen and (max-width: 479px) {
    .custom-header h2 {
        font-size: 20px;
        margin: 0;
    }

    .navbar-light .navbar-toggler-icon {
        height: 18px;
        margin: -3px 0 0;
    }

    .navbar.navbar-expand-xxl.navbar-light app-app-nav {
        top: 100px;
    }

    body .custom-header {
        padding: 10px 15px 60px 15px !important;
    }

    .navbar.navbar-expand-xxl .navbar-toggler {
        left: 15px;
        top: -86px;
    }

    .custom-header .row .col-4:nth-child(2) {
        top: 50px;
    }

    .profile-name {
        height: 30px;
        width: 30px;
        line-height: 30px;
        font-size: 14px;
    }

    .right-head.col-4 {
        max-width: 70px;
    }

    .custom-header h2 {
        width: calc(100% - 70px);
        font-size: 17px;
    }

    .pagination-main {
        display: inline-block;
        padding: 20px 0;
        width: 100%;
    }

    .top_right_profile h3 {
        font-size: 20px;
    }

    p.ng-star-inserted {
        margin: 0 0 20px 0px;
    }

    body .top-row {
        padding: 0px 0px 20px;
    }

    .custom-row .radiusbtn:nth-child(2) {
        padding: 5px 15px 5px 8px;
        margin: 0px 0px 0px 10px !important;
    }

    .custom-row .radiusbtn:nth-child(3) {
        padding: 5px 12px;
        margin: 0px 0px 0px 10px !important;
    }

    body .accordion-header[_ngcontent-ng-c111044940] .edit[_ngcontent-ng-c111044940] {
        margin: 7px 5px 0 0;
    }

    .top-right-sec .input-group .btn img {
        margin: -6px 0 0;
    }

    .candidate_details .top-left-details {
        display: block !important;
    }

    .candidate_details .top_profile img {
        max-height: 85px !important;
    }

    .candidate_details .top_right_profile {
        padding: 0 !important;
        margin: 10px 0 0;
    }

    .candidate_details .top_right_profile .blue_link {
        margin: 3px 0 0;
    }

    .candidate_details .light-blue-btn {
        font-size: 12px !important;
    }

    .candidate_details .top-right-details div:last-child {
        float: left;
        margin: 10px 0 0px;
    }

    .candidate_details .custom-dots-btn {
        margin: -7px 0 0 10px !important;
    }

    .candidate_details .top-right-details {
        display: inline-block;
    }

    #accordionPanelsStayOpenExample-three {
        min-width: 250px;
    }

    .accordion-body li a {
        word-break: break-all !important;
    }
}

.sidelogobar {
    background: none;
    border: 0px;
    text-align: left;
    position: relative;
    left: -19px;
}

.modal-backdrop.show {
    opacity: var(--bs-backdrop-opacity);
    display: none;
}

.sidebar_modal .nav-item:first-child,
.sidebar_modal .nav-item {
    margin-bottom: 0px;
}

body {
    padding-right: 0px;
}

.subject_body_right.w-100 .dropdown.d-inline-block button {
    padding: 10px !important;
}

.subject_body_right.w-100 .d-flex.justify-content-between.align-items-top.mb-3 {
    margin-bottom: 11px !important;
}

.subject_body {
    /* overflow-y: auto; */
    height: calc(100vh - 121px);
}

.subject_body::-webkit-scrollbar {
    width: 1em;
}

.subject_body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.subject_body::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

.subject_body .dropdown-item {
    cursor: pointer;
}

.closebutton {
    background: no-repeat;
    border: 0px;
    width: 25px;
}

.closebutton img {
    width: 14px;
}